import {
    faCartPlus,
    faHeart,
    faTicketAlt,
    faUser,
    faShareSquare
  } from '@fortawesome/free-solid-svg-icons';
  import RadioInput2 from '../../../sections/ui/formfields/RadioInput2';

  import { faHeart as farHeart } from '@fortawesome/free-regular-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import React, { useEffect, useState } from 'react';
  import Breadcrumbs from '../../../sections/events/EventDtlsBreadCrumbs';
  import EventSlider from '../../../sections/events/EventsDetailsSlider';
  import StarRating from '../../../sections/ui/StarRating.js';
  import Popup from '../../../sections/ui/Popup/Popup';
  import ReviewScoreBoard from '../../../sections/ui/Review/ReviewScoreBoard';
  import Ticket from '../../../sections/ui/Ticket/Ticket';
  import Alert from '../../../sections/ui/Alert';
  import ReactTooltip from 'react-tooltip';
  import { PhotoSwipeGallery } from 'react-photoswipe';
  import 'react-photoswipe/lib/photoswipe.css';
  import axios from 'axios';
  import parse from 'html-react-parser';
  import { EVENT_BY_SLUG_API, BACKEND_URL } from '../../../APIConfig';
  import ReviewList from '../../../sections/ui/Review/ReviewList';
  import moment from 'moment';
  import { useDispatch, useSelector } from 'react-redux';
  import { CART_ADD_ITEM_TO_STORAGE } from '../../../service/constants/cartConstants';
  import { API_DOMAIN, IMAGE_BASE_URL } from '../../../Config';
  import { useHistory } from 'react-router-dom';
  import { useAlert } from 'react-alert';
  import CustomLoader from '../../../sections/ui/CustomLoader';
  import { FacebookShareButton, FacebookIcon, EmailShareButton } from "react-share";
  import Audience from './Audience';
  import {useTranslation} from "react-i18next"; 
  import LabeledInput from '../../../sections/ui/formfields/LabeledInput';
  import { faMailchimp } from '@fortawesome/free-brands-svg-icons';
  import { useLocation } from "react-router-dom";
import { clearCart,getCartData,removeFromCart } from '../../../service/actions/cartActions';

  function EventDetailsFrame(props) {
    const {t, i18n} = useTranslation('common')
    const [galleryImages, setGalleryImages] = useState([]);
  
    const slug = props.match.params.event;
    const eventid = props.match.params.eventid;
    const cartItems = useSelector(state => state.cart.cartItems)

    const dispatch = useDispatch();
    const [reviews, setReviews] = useState([]);
    const [ticketBuy, setticketBuy] = useState(false);
    const [averageReview, setAverageReview] = useState(0);
    const [averageReviewArray, setAverageReviewArray] = useState([]);
  
    const [read_more, setMore] = useState(false);
    const [lessText, setlessthan] = useState(false);
    const [showFields, setShowFields] = useState(false);
    // const [addToCalendar, setAddToCalendar] = useState(false);
    const [ticketsPopup, setTicketsPopup] = useState(true);
    const [invitePopup, setInvitePopup] = useState(false);
    const [reviewAdded, setReviewAdded] = useState(false);
    const [currentUserReview, setCurrentUserReview] = useState();
    const alert = useAlert();
  
    const [loadingFav, setLoadingFav] = useState(false);
    const [isFav, setIsFav] = useState(false);
    // const [favId, setFavId] = useState();
    // const [event, setEvent] = useState({
    //   title: '',
    //   start: new Date()
    // });
    const [eventDetailstate, setEventDetailstate] = useState({
      booked_tickets: [],
      currency: '',
      ticket_data: [],
      tickets: [],
      total_capacity: '',
      data: ''
    });
  
    const [loading, setLoading] = useState(false);
    const [country, setCountry] = useState();
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [customerEmailConfirm, setCustomerEmailConfirm] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [customerGender, setCustomerGender] = useState('Male');
    const [isEventExpired, setisEventExpired] = useState(false);

    const [senderName, setsenderName] = useState('');
    const [senderEmail, setSenderEmail] = useState('');
    const [ReceiverEmail, setReceiverEmail] = useState('');
    const [ReceiverMessage, setReceiverMessage] = useState('');
  
    const history = useHistory();
    const authUser = localStorage.getItem('user');
    const authData = JSON.parse(authUser);
  
    // set header for sending requests
    const headers = {
      Authorization: `Bearer ${authData && authData.access_token}`,
      accept: 'application/json'
    };
  
    const countries = useSelector(state => state.countries.countries);
  
    const getId = url => {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
  
      return match && match[2].length === 11 ? match[2] : null;
    };
  
    useEffect(() => {
      getEventDetails();
      checkandremove();
    }, [countries]);
  
    useEffect(() => {
      getReviews();
    }, [reviewAdded]);
  
    const InvitePopupClick = () => {
      setInvitePopup(!invitePopup);
    };
  
    useEffect(() => {
      const getFavorite = async () => {
        if (authData) {
          try {
            setLoading(true);
            const { data } = await axios.post(
              `${API_DOMAIN}api/favorite/show`,
              {},
              { headers }
            );
            const currentIsFav = data.data.find(fav => +eventid === fav.event_id);
            if (currentIsFav) {
              setIsFav(true);
              // setFavId(currentIsFav.id);
            }
          } catch (error) {
            console.error(error);
            // alert.error('Something went wrong please try after some time.');
          }
          setLoading(false);
        }
      };
      getFavorite();
    }, []);
  
    const addToFavouriteHandler = async eventId => {
      if (authData) {
        try {
          setLoadingFav(true);
          await axios.post(
            `${API_DOMAIN}api/favorite/add`,
            { event_id: eventId },
            { headers }
          );
          alert.success(t('AddedToFavorite'));
          setLoadingFav(false);
          setIsFav(true);
        } catch (error) {
          alert.error(t('AddedToFavoriteError'));
          setLoadingFav(false);
          setIsFav(false);
        }
      } else {
        history.replace('/signin');
      }
      setLoadingFav(false);
    };
  
    const removeFromFavoriteHandler = async eventId => {
      if (authData) {
        try {
          setLoadingFav(true);
          await axios.get(`${API_DOMAIN}api/favorite/delete/${eventId}`, {
            headers
          });
          alert.success(t('RemovedToFavorite'));
          setLoadingFav(false);
          setIsFav(false);
        } catch (error) {
          alert.error(t('AddedToFavoriteError'));
          setLoadingFav(false);
          setIsFav(true);
        }
      } else {
        history.replace('/signin');
      }
      setLoadingFav(false);
    };
  
    const getReviews = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          API_DOMAIN + `api/review/show/${eventid}`
        );
        setReviews(data.data);
        const average = data.data.map(review => review.review);
        let star1 = 0;
        let star2 = 0;
        let star3 = 0;
        let star4 = 0;
        let star5 = 0;
        average.forEach(avg => {
          if (avg === 1) {
            star1++;
          }
          if (avg === 2) {
            star2++;
          }
          if (avg === 3) {
            star3++;
          }
          if (avg === 4) {
            star4++;
          }
          if (avg === 5) {
            star5++;
          }
        });
        const calAverage = [star5, star4, star3, star2, star1];
        if (authData) {
          const currentUserId = authData.user_detail.id;
          const cUserReview = data.data.find(
            review => review.user_id === currentUserId
          );
  
          if (cUserReview) {
            setCurrentUserReview({
              review: cUserReview.review,
              description: cUserReview.description
            });
          }
        }
        // (5*252 + 4*124 + 3*40 + 2*29 + 1*33) / (252+124+40+29+33) = 4.11 and change
        const starAverage =
          (5 * calAverage[0] +
            4 * calAverage[1] +
            3 * calAverage[2] +
            2 * calAverage[3] +
            1 * calAverage[4]) /
          calAverage.reduce((a, b) => a + b, 0);
  
        setAverageReviewArray(calAverage);
        setAverageReview(Math.ceil(Math.ceil(starAverage)));
      } catch (err) {
        console.error(err);
      }
      setLoading(false);
    };
  
    const getEventDetails = async () => {
      try {
      
        dispatch(clearCart())
        var userdetail = localStorage.getItem("user");
        localStorage.clear();
        if(userdetail)
        localStorage.setItem("user",userdetail)
        setLoading(true);
        await axios({
          url: EVENT_BY_SLUG_API + slug,
          method: 'GET'
        }).then(function (response) {
          setEventDetailstate({
            booked_tickets: response.data.events.booked_tickets,
            currency: response.data.events.currency,
            ticket_data: response.data.events.ticket_data,
            tickets: response.data.events.tickets,
            total_capacity: response.data.events.total_capacity,
            data: response.data.events.data,
            images: JSON.parse(response.data.events.data.images)
          });
          if (countries) {
            let country = countries.find(
              country => country.id === response.data.events.data.country_id
            );
            setCountry(country);
          }
          let  end_date = moment(response.data.events.data.end_date+" "+response.data.events.data.end_time);
          let server_date = moment(response.data.events.data.server_date);
          if(end_date.isSameOrAfter(server_date)){
            setisEventExpired(false)
          }
          else
          {
          if(response.data.events.data.redirect_url)
           window.location=response.data.events.data.redirect_url
          else
           setisEventExpired(true)
          }
          const imagesGallery = JSON.parse(response.data.events.data.images);
          const imagesGalleryArray =
            imagesGallery && imagesGallery.length > 0
              ? imagesGallery.map(image => ({
                src: `${IMAGE_BASE_URL}/${image}`,
                thumbnail: `${IMAGE_BASE_URL}/${image}`,
                title: 'title',
                w: 1200,
                h: 900
              }))
              : [];
          setGalleryImages(imagesGalleryArray);
          document.documentElement.style.setProperty('--mycolor2',response.data.events.data.button_color);
          document.documentElement.style.setProperty('--mycolor',response.data.events.data.button_color);
          let alltickets = response.data.events.tickets;
          let totalnumber=0;
          for(var i=0;i<alltickets.length;i++)
          {
             if(alltickets[i].iframe_allow==1)
             {
               totalnumber+=1;
             }
          }
          if(totalnumber==0)
          setticketBuy(false)
          else
          setticketBuy(true)
        });
        setLoading(false);

      } catch (err) {
        console.error(err);
      }
    };
  
    const readMoreHndlr = e => {
      e.preventDefault();
      setMore(!read_more);
    };
    useEffect(() => {
      checkHeightHandler();
    }, []);
    const checkHeightHandler = () => {
      let val = document.querySelector('.readmore');
      if (val && val.clientHeight < 500) {
        // setlessthan(true);
      }
    };
  
    let options = {
      //http://photoswipe.com/documentation/options.html
    };
  
    const getThumbnailContent = item => {
      return <img src={item.thumbnail} width={100} height={100} />;
    };
   
    const TicketsPopupClick = () => {
      setShowFields(false)
      setTicketsPopup(true);
      checkandremove();
    };

    const checkandremove = ()=>{
      cartItems.forEach(res=>{
        if(res.ticket_qty<(res.ticket?.buy_limit || res.ticket?.tickets?.buy_limit))
        {
        dispatch(removeFromCart(res.event_id, res.ticket_id))
        }
       }) 
    }  

    const checkonly = ()=>{
      let exist = true;
      cartItems.forEach(res=>{
        if(res.ticket_qty<(res.ticket?.buy_limit || res.ticket?.tickets?.buy_limit))
        {
        exist=false;
        }
       }) 
      return exist;
    }
  
    //--------- add to cart -----------
    const addToCartHandler = () => {
      if (customerEmail && customerName && postalCode && customerGender && (customerEmail==customerEmailConfirm)) {
        dispatch({ type: CART_ADD_ITEM_TO_STORAGE });
        localStorage.setItem("customer_name",customerName);
        localStorage.setItem("customer_email",customerEmail);
        localStorage.setItem("customer_postal",postalCode);
        localStorage.setItem("customer_gender",customerGender);
        alert.success(t('TicketsAddedSuccessfully'));
        history.push('/dashboard/attendee/CheckoutIframe');
      }
      else
      {
        window.alert(t('fillallfields'))
      }
    };

    const getlimit = ()=>{
      let limt = 0;
      cartItems.forEach(res=>{
        if(res.ticket_qty<(res.ticket?.buy_limit || res.ticket?.tickets?.buy_limit))
        {
          limt = res.ticket?.buy_limit || res.ticket?.tickets?.buy_limit;
        }
       }) 
      return limt;
    }

    const moveToForm = () => {
      var itemslength=JSON.parse(localStorage.getItem("cartItems"))
      if(checkonly())
      {
      if (localStorage.getItem("cartItems") && itemslength.length > 0) {
      setTicketsPopup(false);
      setShowFields(true)
      }
      else
      {
        window.alert(t('FirstAddTickets'))
      }
    }
    else
    {
      window.alert(`${t('addminmumtickets').replaceAll("{limit}",getlimit())}`)
    }
    };
  
    //--------- invite send  -----------
    const sendFriendInvitetHandler = async e => {
  
      const currentURL = window.location.href
      const newFormData = new FormData();
      newFormData.append('name', senderName);
      newFormData.append('sender_email', senderEmail);
      newFormData.append('receiver_email', ReceiverEmail);
      newFormData.append('subject', `$${senderName} invites you to ${eventDetailstate.data.title}.`)
      newFormData.append('message', `Hello,<br/><br/>,$${senderName} invites you to ${eventDetailstate.data.title}.<br/>
      <a href='${currentURL}'>Click here for more details.</a><br/><br/> ${ReceiverMessage}`);
      try {
        setLoading(true);
        const { data } = await axios.post(
          `${API_DOMAIN}api/invite`, newFormData,
          { headers }
        );
        setInvitePopup(false);
        alert.success(t('InvitationEmailSuccessfully'));
        
      } catch (error) {
        setInvitePopup(false);
        alert.error(t('AddedToFavoriteError'));
      }
      setLoading(false);
    };
  
    let location = useLocation();
    let currentUrl = "https://www.myticketshop.be/" + location.pathname;
  
    let titlee = eventDetailstate.data.title !== undefined ? eventDetailstate.data.title : "MYTICKETSHOP.BE";
    let image = galleryImages.length > 0 && galleryImages[0].src !== undefined ? galleryImages[0].src : "https://www.myticketshop.be/backend/public/storage/events/July2021/1627025196459.jpg";
  
  
    return (
      <>
        {window.location.pathname.indexOf('/event-frame-details/') >= 0 ? null : <Breadcrumbs title={eventDetailstate.data.title} /> }
        {loading && <CustomLoader style={{ textAlign: 'center' }} />}
        {/* <EventSlider images={eventDetailstate.images} /> */}
        {eventDetailstate.data.allow_iframe==1 && galleryImages.length > 0 && !ticketsPopup && (
          <EventSlider images={galleryImages.map(image => image.src)} />
        )}
         {eventDetailstate.data.allow_iframe==0 && <>
          <section className='background-color-new section-content padding-y bg-white' style={{minHeight:'100vh'}}>
          <div className='container'>
                <div className='card card-color-back'>
                <div className='card-body'>
                  <label>{t('FrameNotAllowed')}</label>
                  </div>
                  </div>
          </div>
          </section>
         </>
         }
        {eventDetailstate.data.allow_iframe==1 && <>
          <section className='section-content padding-y bg-white' style={{minHeight:'100vh',paddingTop:'20px',paddingBottom:'5px'}}>
          {
            !ticketsPopup && <>
              <h1 style={{textAlign:'center'}} className='card-title b d-lg-block notranslate'>
                        {eventDetailstate.data.frame_text}
                      </h1></>
          }
        
          {!loading && (
            <>
              <div className='container'>
                <div className={ticketsPopup?'':'card'}>
                  <div className='row'>

                    <div className='col-12 col-lg-12 order-0 order-lg-1 '>
                      <div className='card-body'>
                    
                      {showFields && <>
                        {/* <form id='customer-details' method='post'>
                        <div className='event-eventDate-wrapper'>
                        <h1 style={{textAlign:'center'}} className='card-title b d-lg-block notranslate'>
                        {eventDetailstate.data.frame_subtext}
                      </h1>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('CustomerName')}
                              id='customerName'
                              name='customerName'
                              value={customerName}
                              onChange={e => {
                               setCustomerName(e.target.value)
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('CustomerEmail')}
                              id='customerEmail'
                              name='customerEmail'
                              value={customerEmail}
                              onChange={e => {
                                setCustomerEmail(e.target.value)
                               }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('PostalCode')}
                              id='postalCode'
                              name='postalCode'
                              value={postalCode}
                              onChange={e => {
                                setPostalCode(e.target.value)
                               }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                          <RadioInput2
                          required
                  label={t('SelectGender')}
                  info=''
                  entries={[
                    {
                      label: t('Male'),
                      value: 'Male',
                      id: 'enable_customerGender',
                      name: 'gender',
                      selected: customerGender === 'Male' ? true : false
                    },
                    {
                      label: t('Female'),
                      value: 'Female',
                      id: 'disable_customerGender',
                      name: 'gender',
                      selected: customerGender === 'Female' ? true : false
                    }
                  ]}
                  onChange={e => {
                    setCustomerGender(e.target.value);
                  }}
                />
                          </div>
                        </div>
         
                        <button
                        onClick={()=>{
                          addToCartHandler()
                        }} 
                              style={{backgroundColor:eventDetailstate.data.button_color,borderColor:eventDetailstate.data.button_color,marginTop:20}}

                              className='btn btn-primary btn'>
                              {t('SendButton')}
                            </button>
                            <button
                             style={{backgroundColor:eventDetailstate.data.button_color,borderColor:eventDetailstate.data.button_color,marginLeft:20,marginTop:20}}
                              onClick={()=>{
                                setShowFields(false)
                              }}
                              className='btn btn-primary btn'>
                              {t('CouponPage.CancelButton')}
                            </button>
                          </div>
                          </form> */}
                                                  <Popup
                                  className='custommodalsecond modal-lg modal-dialog-scrollable'
                                  openModal={showFields}
                                  closeModal={TicketsPopupClick}
                                  isFrame={true}
                                  buttonColor={eventDetailstate.data.button_color}
                                  title={
                                    <>
                                      <FontAwesomeIcon icon={faTicketAlt} className='mr-1' />
                                      {t('guestuser')}
                                    </>
                                  }
                                  footer={
                                    <>
                                      <span className='close'></span>
                                      <span>
                                        {t('SendButton')}
                                      </span>
                          
                                    </>
                                  }
                                  footerAction={addToCartHandler}>
                                  <>
                                  <div>
                         <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('CustomerName')}
                              id='customerName'
                              name='customerName'
                              value={customerName}
                              onChange={e => {
                               setCustomerName(e.target.value)
                              }}
                              required
                            />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('CustomerEmail')}
                              id='customerEmail'
                              name='customerEmail'
                              value={customerEmail}
                              onChange={e => {
                                setCustomerEmail(e.target.value)
                               }}
                              required
                            />
                          </div>
                        </div> 
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('CustomerEmailConfirm')}
                              id='customerEmailConfirm'
                              name='customerEmailConfirm'
                              value={customerEmailConfirm}
                              onChange={e => {
                                setCustomerEmailConfirm(e.target.value)
                               }}
                              required
                            />
                          </div>
                        </div> 
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                            <LabeledInput
                              label={t('PostalCode')}
                              id='postalCode'
                              name='postalCode'
                              value={postalCode}
                              onChange={e => {
                                setPostalCode(e.target.value)
                               }}
                              required
                            />
                          </div>
                        </div>   
                        <div className='row'>
                          <div className='col-12 col-md-12'>
                          <RadioInput2
                          required
                  label={t('SelectGender')}
                  info=''
                  entries={[
                    {
                      label: t('Male'),
                      value: 'Male',
                      id: 'enable_customerGender',
                      name: 'gender',
                      selected: customerGender === 'Male' ? true : false
                    },
                    {
                      label: t('Female'),
                      value: 'Female',
                      id: 'disable_customerGender',
                      name: 'gender',
                      selected: customerGender === 'Female' ? true : false
                    }
                  ]}
                  onChange={e => {
                    setCustomerGender(e.target.value);
                  }}
                />
                          </div> 
                          </div>             
                        </div>
                        </>
                                </Popup>
                      </>
                       }
                        {!showFields && <>
                          {
                            !ticketsPopup && <>
                           
                          <form id='add-to-cart-form' method='post'>
                          <div className='event-eventDate-wrapper'>
                            <dl>
                              <dt className='text-muted'>
                              <h1 style={{textAlign:'center'}} className='card-title b d-lg-block notranslate'>
                        {eventDetailstate.data.frame_subtext}
                      </h1>
                              </dt>
                              <dd>
                                <div className='text-center'>
                                  <div className='display-inline-block'>
                                    <div className='display-inline-block'>
                                      <span className='font-size-3rem mx-1'>
                                        {eventDetailstate.data && eventDetailstate.data.start_date && moment(
                                          eventDetailstate.data.start_date
                                        ).format('DD')}
                                      </span>
                                    </div>
                                    <div className='display-inline-block mr-3'>
                                      <div>
                                        <span className='font-size-1rem'>
                                          {eventDetailstate.data && eventDetailstate.data.start_date && moment(
                                            eventDetailstate.data.start_date
                                          ).format('MMM')}
                                        </span>
                                      </div>
                                      <div>
                                        <span>
                                          {eventDetailstate.data && eventDetailstate.data.start_date && moment(
                                            eventDetailstate.data.start_date
                                          ).format('YYYY')}
                                        </span>
                                      </div>
                                    </div>
                                    <div className='mb-2'>
                                      <span className='text-muted b'>
                                        {eventDetailstate.data && eventDetailstate.data.start_time && moment(
                                          `${eventDetailstate.data.start_date} ${eventDetailstate.data.start_time}`
                                        ).format('HH:mm')}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='display-inline-block'>
                                    <div className='display-inline-block'>
                                      <span className='mx-1'>
                                        <p
                                          style={{
                                            marginLeft: -2,
                                            marginRight: 5,
                                            color: 'gray'
                                          }}>
                                          TO
                                        </p>
                                      </span>
                                    </div>
                                  </div>
                                  <div className='display-inline-block'>
                                    <div className='display-inline-block'>
                                      <span className='font-size-3rem mx-1'>
                                        {eventDetailstate.data && eventDetailstate.data.end_date && moment(
                                          eventDetailstate.data.end_date
                                        ).format('DD')}
                                      </span>
                                    </div>
                                    <div className='display-inline-block'>
                                      <div>
                                        <span className='font-size-1rem'>
                                          {eventDetailstate.data && eventDetailstate.data.end_date && moment(
                                            eventDetailstate.data.end_date
                                          ).format('MMM')}
                                        </span>
                                      </div>
                                      <div>
                                        <span>
                                          {eventDetailstate.data && eventDetailstate.data.end_date && moment(
                                            eventDetailstate.data.end_date
                                          ).format('YYYY')}
                                        </span>
                                      </div>
                                    </div>
                                    <div className='mb-2'>
                                      <span className='text-muted b'>
                                        {eventDetailstate.data && eventDetailstate.data.end_time && moment(
                                          `${eventDetailstate.data.end_date} ${eventDetailstate.data.end_time}`
                                        ).format('HH:mm')}
                                      </span>
                                    </div>
                                  </div>
                                  <div className='clearfix'></div>
                                </div>
                              </dd>
                            </dl>
                            <hr className='hr-md' />
                            <button
                                  type='button'
                                  style={{backgroundColor:eventDetailstate.data.button_color,borderColor:eventDetailstate.data.button_color}}
                                  className='btn btn-primary btn-block'
                                  onClick={() => {
                                    navigator.clipboard.writeText(`https://myticketshop.be/event/${eventDetailstate.data.slug}`)
                                    alert.success(t('copytoclipboard')); 
                                     }}
                                 >
                                  <FontAwesomeIcon icon={faShareSquare} /> {t('shareurlupdate')}
                                </button>
                            {ticketBuy && <>
                            <hr className='hr-md' />
                            <dl className='mt-5'>
                              <dt className='text-muted'>{t('EventDetailPage.Tickets')}</dt>
                              <dd className='mr-0'>
                                {!isEventExpired?<>
                                  <button
                                  type='button'
                                  style={{backgroundColor:eventDetailstate.data.button_color,borderColor:eventDetailstate.data.button_color}}
                                  className='btn btn-primary btn-block'
                                  onClick={TicketsPopupClick}>
                                  <FontAwesomeIcon icon={faTicketAlt} /> {t('EventDetailPage.Tickets')}
                                </button></>:<></>}
                                  
                                
                              </dd>
                            </dl>
                            </>}
                            <hr className='hr-md my-4' />

{eventDetailstate &&
  eventDetailstate.data &&
  eventDetailstate.data.user_detail && (
    <dl className='my-4'>
      <dt className='text-muted'>
        <span className='float-left'>{t('EventDetailPage.Organizer')}</span>
        <div className='clearfix'></div>
      </dt>
      <dd>
        <h5 className='notranslate'>
          {eventDetailstate.data.user_detail.organiser_logo === null ? '' :
            <img src={BACKEND_URL + eventDetailstate.data.user_detail.organiser_logo} style={{width: '100%'}} ></img>
          }
        </h5>
      </dd>
      <dt className='event-details-location'>
        <p>{t('EventDetailPage.Name')}: </p>
        <p className='notranslate'>
          {eventDetailstate.data.user_detail.organisation}
        </p>
      </dt>
      {eventDetailstate.data.user_detail.url === null ? '' :
        <dt className='event-details-location'>
          <p>{t('EventDetailPage.URL')}: </p>
          <p className='notranslate'>
            <a href={eventDetailstate.data.user_detail.url.includes("http") ? eventDetailstate.data.user_detail.url : 'https://'+eventDetailstate.data.user_detail.url} target="_blank">{eventDetailstate.data.user_detail.url}</a>
          </p>
        </dt>
      }
      {eventDetailstate.data.user_detail.email === null ? '' :
        <dt className='event-details-location'>
          <p>{t('EventDetailPage.ContactEmail')}: </p>
          <p className='notranslate'>
          {eventDetailstate.data.user_detail.email}
          </p>
        </dt>
      }
      {eventDetailstate.data.user_detail.phone === null ? '' :
        <dt className='event-details-location'>
          <p>{t('EventDetailPage.ContactPhone')}: </p>
          <p className='notranslate'>
          {eventDetailstate.data.user_detail.phone}
          </p>
        </dt>
      }
    </dl>
  )}
<hr />
                            <dl>
                            <dt className='text-muted'>
                              <span className='float-left'>{t('EventDetailPage.EventDetail')}</span>
                              <div className='clearfix'></div>
                            </dt>
                              {eventDetailstate.data && eventDetailstate.data.venue && (
                              <dt className='event-details-location' style={{borderColor:eventDetailstate.data.url_color}}>
                                <p>{t('EventDetailPage.Venue')}: </p>
                                <p className='notranslate' style={{borderColor:eventDetailstate.data.url_color}}>
                                  {eventDetailstate.data.venue}
                                </p>
                              </dt>
                              )}
                              {eventDetailstate.data && eventDetailstate.data.address && (
                              <dt className='event-details-location' style={{borderColor:eventDetailstate.data.url_color}}>
                                <p>{t('EventDetailPage.Address')}: </p>
                                <p className='notranslate'>
                                  {eventDetailstate.data.address}
                                </p>
                              </dt>
                              )}
                              {eventDetailstate.data && eventDetailstate.data.zipcode && (
                              <dt className='event-details-location' style={{borderColor:eventDetailstate.data.url_color}}>
                                <p>{t('EventDetailPage.PostalCode')}: </p>
                                <p className='notranslate'>
                                  {eventDetailstate.data.zipcode}
                                </p>
                              </dt>)}
                              {eventDetailstate.data && eventDetailstate.data.city && (
                              <dt className='event-details-location' style={{borderColor:eventDetailstate.data.url_color}}>
                                <p>{t('EventDetailPage.City')}: </p>
                                <p className='notranslate'>
                                  {eventDetailstate.data.city}
                                </p>
                              </dt>
                              )}
  
                              {country && (
                                <dt className='event-details-location' style={{borderColor:eventDetailstate.data.url_color}}>
                                  <p>{t('EventDetailPage.Country')}: </p>
                                  <p className='notranslate'>
                                    {country.country_name}
                                  </p>
                                </dt>
                              )}
                              
                            </dl>

                          </div>
                        </form>
                        </>
                          }
                        <Popup
                                  className='custommodal modal-lg modal-dialog-scrollable'
                                  openModal={ticketsPopup}
                                  isFrame={true}
                                  buttonColor={eventDetailstate.data.button_color}
                                  closeModal={TicketsPopupClick}
                                  title={
                                    <>
                                      <FontAwesomeIcon
                                        icon={faTicketAlt}
                                        className='mr-1'
                                      />
                                      {t('EventDetailPage.BuyTickets')}
                                    </>
                                  }
                                  footer={
                                    <>
                                      <span
                                        style={{
                                          
                                          cursor:
                                            eventDetailstate.tickets.length <= 0
                                              ? 'not-allowed'
                                              : 'pointer'
                                        }}  className="custom-font">
                                        <FontAwesomeIcon icon={faCartPlus} /> {t('EventDetailPage.AddToCartButton')}
                                      </span>
                                    </>
                                  }
                                  footerAction={moveToForm}>
                                  <Ticket
                                    title={eventDetailstate.data.title}
                                    isFrame={true}
                                    buttonColor={eventDetailstate.data.button_color}
                                    urlColor={eventDetailstate.data.url_color}
                                    date={`${moment(
                                      eventDetailstate.data.start_date +
                                      ' ' +
                                      eventDetailstate.data.start_time
                                    ).format(
                                      'dddd DD MMM YYYY HH:mm'
                                    )} - ${moment(
                                      eventDetailstate.data.end_date +
                                      ' ' +
                                      eventDetailstate.data.end_time
                                    ).format('dddd DD MMM YYYY HH:mm')}`}
                                    venue={`${eventDetailstate.data.venue} ${eventDetailstate.data.zipcode}, ${eventDetailstate.data.city}`}
                                    category={eventDetailstate.data.category_name}
                                    show_ticket_limit={eventDetailstate.data.show_ticket_limit}
                                    show_limit_purchase = {eventDetailstate.data.show_ticket_limit_purchased}
                                    categoryInfo=''
                                    salePrice=''
                                    price=''
                                    saleStartDate=''
                                    saleEndDate
                                    ticketsLeft=''
                                    maxTicketsAllowed=''
                                    tickets={eventDetailstate.ticket_data.tickets?.sort(function(a, b){return a.order - b.order})}
                                    event={eventDetailstate.data}
                                    country={country}
                                  />
                                </Popup>
                        </>}
                       
                        {loadingFav && (
                          <CustomLoader style={{ textAlign: 'center' }} />
                        )}
 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
        </>}
        
      </>
    );
  }
  
  export default EventDetailsFrame;
  